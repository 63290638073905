import React, { useState } from "react"
import { FaAlignRight } from "react-icons/fa"

import {
  Nav,
  Brand,
  NavCenter,
  NavRight,
  NavLinkCenter,
  NavLinkRight,
  BurguerMenu,
  Collapse,
} from "./styles"

function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen)
  }

  return (
    <Nav>
      <div className="container">
        <Brand href="/">Eve De Souza</Brand>
        <BurguerMenu onClick={handleNavbar} aria-label="Abrir Menu">
          <FaAlignRight className="navbar-toggler-icon"></FaAlignRight>
        </BurguerMenu>
        <Collapse open={navbarOpen}>
          <NavCenter>
            <li>
              <NavLinkCenter to="/#about">About</NavLinkCenter>
            </li>
            <li>
              <NavLinkCenter to="/#services">Services</NavLinkCenter>
            </li>
            <li>
              <NavLinkCenter to="/#gallery">Gallery</NavLinkCenter>
            </li>
            <li>
              <NavLinkCenter to="/#contact">Contact</NavLinkCenter>
            </li>
          </NavCenter>
          <NavRight>
            <li>
              <NavLinkRight href="https://api.whatsapp.com/send?phone=+556391051370">
                Appointment
              </NavLinkRight>
            </li>
          </NavRight>
        </Collapse>
      </div>
    </Nav>
  )
}

export default Navbar
