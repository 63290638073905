import React from "react"

import imageOne from "../../images/image-1.png"
import imageTwo from "../../images/image-2.png"

import {
  Title,
  Text,
  Masthead,
  Image,
  Container,
  HeaderInfo,
  HeaderImage,
  Button,
  CenterImage,
} from "./styles"

const Header = ({information}) => (
  <Masthead id="home">
    <div className="container">
      <Container>
        <HeaderInfo>
          <Title>{information && information.title}</Title>
          <Text>{information && information.subtitle}</Text>
          <Button to="/#services">Explore Services</Button>
        </HeaderInfo>
        <HeaderImage>
          <Image src={imageOne} alt="Header Image" />
        </HeaderImage>
      </Container>
      <CenterImage src={imageTwo} alt="Image center" />
    </div>
  </Masthead>
)

export default Header
