import styled from "styled-components"
import { colors } from "../../styles"
import { Link } from "gatsby"

import palmeiraImage from "../../images/palmeira.png"

export const Masthead = styled.div`
  height: 100vh;
  min-height: 500px;
  background: ${colors.primary};
  background-position: left 0 bottom 0;
  background-repeat: no-repeat;
  background-image: url(${palmeiraImage});
  background-blend-mode: multiply;
`

export const Title = styled.h1`
  color: ${colors.white};
  font-weight: bold;
  font-size: 4rem;
  line-height: 160%;
`

export const Text = styled.p`
  margin-top: 1rem;
  line-height: 160%;
  font-size: 1.5rem;
  color: ${colors.white};
`

export const Image = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 767.98px) {
    display: none;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HeaderInfo = styled.div`
  margin-top: 12rem;
`

export const HeaderImage = styled.div`
  margin-top: 10rem;

  img {
    border-radius: 0px 70px 70px 0px;
    z-index: 11;
  }
`

export const Button = styled(Link)`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #42501a !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.white};
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  border-radius: 1.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 2rem;

  &:hover {
    background-color: #cccccc;
    border-color: #cccccc;
  }
`

export const CenterImage = styled.img`
  border-radius: 70px 70px 0px 70px;
  display: block;
  margin-left: 45%;
  margin-right: auto;
  margin-top: -7rem;
  width: 20rem;
  z-index: 10;

  @media (max-width: 991.98px) {
    display: none;
  }
`
