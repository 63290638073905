import styled from "styled-components"
import { colors, devices } from "../../styles"
import { Link } from "gatsby"

export const Nav = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  margin-top: 2.5rem;
  background: transparent;

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 1rem;
  }
`

export const Brand = styled.a`
  color: ${colors.white};
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 2.25rem;
  line-height: inherit;
  white-space: nowrap;
  text-decoration: none;
  font-weight: bold;
`

export const BurguerMenu = styled.button`
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: ${colors.white};
  display: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }

  @media ${devices.laptop} {
    display: block;
    margin-left: auto;
  }
`

export const Collapse = styled.div`
  display: flex;
  flex: 1;

  @media ${devices.laptop} {
    flex: 0 1 auto;
    flex-direction: column;
    width: 100%;
    background: #fff;
    display: ${props => (props.open ? "flex" : "none")};
  }
`

export const NavCenter = styled.ul`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin: 0 auto;

  @media ${devices.laptop} {
    flex-direction: column;
    margin: 0;
  }
`

export const NavRight = styled.ul`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  list-style: none;
  flex-wrap: wrap;
  margin-left: auto;

  @media ${devices.laptop} {
    flex-direction: row;
    margin: 0;
  }

  a {
    color: ${colors.white};

    @media ${devices.laptop} {
      color: ${colors.title};
    }
  }
`

export const NavLinkCenter = styled(Link)`
  display: block;
  padding: 0.5rem 1rem;
  color: ${colors.white};
  text-decoration: none;
  font-size: 1.15rem;

  @media (max-width: 991.98px) {
    color: ${colors.primary};
  }
`

export const NavLinkRight = styled.a`
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.primary} !important;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: ${colors.white};
  border: 1px solid ${colors.white};
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  border-radius: 1.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #cccccc;
    border-color: #cccccc;
  }
`
