import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing:border-box
}

@media (max-width: 1080px) {
  html {
      font-size:93.75%
  }
}

@media (max-width: 720px) {
  html {
      font-size:87.5%
  }
}

html,
body {
  height: 100%;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  background: #fff	;
  color: #757575;
}

body{
 display: flex;
 flex-direction: column;
 font:400 16px Roboto, sans-serif
}

button {
  cursor:pointer
}

a {
  text-decoration: none;
  color:inherit
}

#page-content {
  flex: 1 0 auto;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
`
