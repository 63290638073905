import React from "react"
import PropTypes from "prop-types"
import { GlobalStyle } from "../styles/global"

import Header from "./Header"
import Navbar from "./Navbar"

const Layout = ({ children, information }) => {
  return (
    <>
      <GlobalStyle />
      <Navbar />
      <Header information={information} />
      <main id="page-content">{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
